const baseUrl = window.location.origin;
const API_URL = "https://dev-berta.coderslab.pl/api";

export default {
  API_URL,
  baseUrl,
  googleClientId: "860288514084-i88n4bdj8i536qeeh86q2jlhtraoqh76.apps.googleusercontent.com",
    googleClientSecret: "be_kDqPVENvSdBxezvp185bR",
   sentryDsn: "https://84a0033521c049b3ad65fa17137f1492@sentry.io/1318559"
};
